<template>
  <div class="rule-block d-flex">
    <div class="rule-operator pt-3">
      <span v-if="showGroupOperator()">{{ groupOperator }}</span>
    </div>
    <div class="align-items-center rule-box">
      <div class="row">
        <div class="col-12">
          <div
            :class="[
              'p-2',
              'rule-box-cnt',
              'form-inline',
              'std-border',
              'rounded',
              'depth-' + ruleDepth(),
              query.error ? 'rule-error' : ''
            ]"
            v-if="isRule"
          >
            <div class="px-1 mb-1">
              <!-- <b-form-select
                class="form-control"
                v-model="query.ruleId"
                @change="ruleIdChanged"
                :options="baseOptions"
              ></b-form-select> -->
              <multiselect
                v-model="ruleIdObj"
                track-by="value"
                label="text"
                :options="baseOptions"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                @close="ruleIdChanged"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.text }}</span>
                </template>
              </multiselect>
            </div>
            <div
              class="px-1 mb-1"
              v-if="chosenRule && chosenRule.label == 'Select Institutions'"
            >
              <b-row class="ml-auto">
                <multiselect
                  v-model="sourceObj"
                  track-by="value"
                  label="label"
                  :options="selectInstitutionOptions"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  placeholder="Select one"
                  class="simple-select group-select form-control rounded advanced-query-select value-multi-select ml-2"
                  @close="selectInstitution()"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option-text">{{ props.option.label }}</span>
                  </template>
                </multiselect>
                <!-- <select
                  v-model="query.source"
                  @change="clearResult()"
                  class="form-control"
                >
                  <option value="null" selected disabled>
                    Please Select
                  </option>
                  <option value="include">Include</option>
                  <option value="exclude">Exclude</option>
                </select> -->
                <b-button
                  v-if="query.source && query.value && query.value.length == 0"
                  class="mx-3 px-3 my-1 float-right"
                  pill
                  size="sm"
                  @click="showInstitutionsModal(query.id)"
                  variant="dark"
                  ><b>Select FI</b></b-button
                >
                <b-button
                  v-if="query.source && query.value && query.value.length > 0"
                  class="mx-3 px-3 my-1 float-right"
                  pill
                  size="sm"
                  @click="showInstitutionsModal(query.id)"
                  variant="dark"
                  ><b>{{ query.value.length }} Institutions Selected</b>
                </b-button>
                <b-button
                  v-if="query.source && query.value && query.value.length == 0"
                  class="mx-3 px-3 my-1 float-right"
                  pill
                  size="sm"
                  @click="showUploadInstitutionsModal(query.id)"
                  variant="dark"
                  ><b>Upload FIs</b></b-button
                >
                <SelectInstitutionModal
                  :query="query"
                  :rule="this"
                ></SelectInstitutionModal>
                <UploadInstitutionModal
                  :query="query"
                  :rule="this"
                ></UploadInstitutionModal>
              </b-row>
            </div>
            <div
              class="px-1 mb-1"
              v-else-if="chosenRule && chosenRule.label == 'Job Search'"
            >
              <b-row class="ml-auto">
                <label class="mx-3" for="input-1">Search Criteria</label>
                <input
                  class="form-control rounded mt-1"
                  v-model="query.value[0]"
                  @focus="disableClone()"
                  @keyup="clearResult()"
                  @blur="fetchCount()"
                  data-form-type="other"
                />
                <label class="mx-3" for="input-2">From Date</label>
                <input
                  type="date"
                  class="form-control rounded mt-1"
                  v-model="query.value[1]"
                  @focus="disableClone()"
                  @keyup="clearResult()"
                  @blur="fetchCount()"
                  data-form-type="other"
                />
                <span class="m-2" v-if="query.loading">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <b-button
                  class="m-2 px-3 float-right"
                  pill
                  size="sm"
                  v-b-modal="'jobSearch' + query.id"
                  v-if="!query.loading && !hideResultCount"
                  variant="dark"
                  ><b>View Results</b></b-button
                >
                <JobSearch :query="query" :rule="this"></JobSearch>
              </b-row>
            </div>

            <div
              class="px-1 mb-1"
              v-else-if="chosenRule && chosenRule.label == 'Site Search'"
            >
              <b-row class="ml-auto">
                <label class="mx-3" for="input-1">Search Criteria</label>
                <input
                  class="form-control rounded mt-1"
                  v-model="query.value[0]"
                  @focus="disableClone()"
                  @keyup="clearResult()"
                  @blur="fetchCount()"
                  @keyup.enter="fetchSiteResults()"
                  ref="siteKeyword"
                  data-form-type="other"
                />
                <span class="m-2" v-if="query.loading">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <b-button
                  class="m-2 px-3 float-right"
                  pill
                  size="sm"
                  v-b-modal="`siteSearch_${query.id}`"
                  v-if="!query.loading && !hideResultCount"
                  variant="dark"
                  ><b>View Results</b></b-button
                >
                <SiteSearch :query="query" :rule="this"></SiteSearch>
              </b-row>
            </div>
            <div
              class="px-1 mb-1"
              v-else-if="chosenRule && chosenRule.label == 'Contact Search'"
            >
              <b-row class="ml-auto">
                <label class="mx-3" for="input-1">Title Search Criteria</label>
                <input
                  class="form-control rounded mt-1"
                  v-model="query.value[0]"
                  @focus="disableClone()"
                  @keyup="clearResult()"
                  @blur="fetchCount()"
                  @keyup.enter="fetchContactResults()"
                  ref="contactKeyword"
                  data-form-type="other"
                />
                <span class="m-2" v-if="query.loading">
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
                <b-button
                  class="m-2 px-3 float-right"
                  pill
                  size="sm"
                  v-b-modal="`contactSearch_${query.id}`"
                  v-if="!query.loading && !hideResultCount"
                  variant="dark"
                  ><b>View Results</b></b-button
                >
                <span v-if="!query.loading && !hideResultCount"
                  ><i class="pl-1"
                    >(Results are the number of FIs with employee titles
                    <br />that match criteria.)</i
                  ></span
                >
                <ContactSearch :query="query" :rule="this"></ContactSearch>
              </b-row>
            </div>
            <div class="px-1 mb-1" v-else-if="query.ruleId">
              <div
                @click="showTreeModal"
                class="metric-select-input form-control rounded"
                v-if="isTreeSelectionRule"
              >
                {{ query.operand ? query.operandLabel : 'Please Select' }}
                <i
                  class="fa fa-external-link link-text pl-2"
                  aria-hidden="true"
                ></i>
              </div>
              <multiselect
                v-model="segOperand"
                track-by="id"
                label="label"
                :options="ruleOperands"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select value-multi-select "
                @close="setSegmentationOperand"
                v-else-if="isSegmentationRule"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.label }}</span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="d-flex">
                    <div class="option-text" :id="`at-${props.option.id}`">
                      {{ props.option.label }}
                    </div>
                  </div>
                  <b-popover
                    :target="`at-${props.option.id}`"
                    triggers="hover"
                    placement="right"
                    boundary="window"
                  >
                    {{ getSegmentationDescription(props.option) }}
                  </b-popover>
                </template>
              </multiselect>
              <multiselect
                v-model="operandObj"
                track-by="id"
                label="label"
                :options="ruleOperands"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select"
                @close="setDependants"
                v-else
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.label }}</span>
                </template>
              </multiselect>
              <!-- <select
                class="form-control operand-select"
                v-model="query.operand"
                @change="setDependants"
                v-else
              >
                <option value="null" selected disabled> Please Select</option>
                <option
                  v-for="(operand, index) in ruleOperands"
                  :key="index"
                  :value="operand.id"
                  >{{ operand.label }}</option
                >
              </select> -->
            </div>

            <div class="px-1 mb-1" v-if="hasChildOperand()">
              <multiselect
                v-model="childOperandObj"
                track-by="id"
                label="label"
                :options="ruleChildOperands"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select"
                @close="setChildDependants"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.label }}</span>
                </template>
              </multiselect>
              <!-- <select
                class="form-control child-operand-select"
                v-model="query.childOperand"
                @change="setChildDependants"
              >
                <option value="null" selected disabled> Please Select </option>
                <option
                  v-for="(childOperand, index) in ruleChildOperands"
                  :key="index"
                  :value="childOperand.id"
                  >{{ childOperand.label }}</option
                >
              </select> -->
            </div>

            <div class="px-1 mb-1" v-if="isVendorFit()">
              <div class="d-inline px-2 font-weight-bold">
                AND
              </div>
              <multiselect
                v-model="fitTypeObj"
                track-by="id"
                label="label"
                :options="fitTypeOperands"
                :multiple="false"
                :close-on-select="true"
                :showLabels="false"
                placeholder="Select one"
                class="simple-select group-select form-control rounded advanced-query-select"
                @close="setFitTypeDependents"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option-text">{{ props.option.label }}</span>
                </template>
              </multiselect>
              <!-- <select
                class="form-control"
                v-model="query.fitType"
                @change="setFitTypeDependents"
              >
                <option value="null" selected disabled> Please Select </option>
                <option
                  v-for="(fitType, index) in fitTypeOperands"
                  :key="index"
                  :value="fitType.id"
                  >{{ fitType.label }}</option
                >
              </select> -->
            </div>

            <div class="px-1 mb-1" v-if="hasOperand">
              <div v-if="ruleOperators.length === 1">
                <i class="fa fa-arrow-right"></i>
              </div>
              <div v-else>
                <multiselect
                  v-model="operatorObj"
                  track-by="id"
                  label="label"
                  :options="ruleOperators"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  placeholder="Select one"
                  class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                  @close="setOperatorDependants"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option-text">{{ props.option.label }}</span>
                  </template>
                </multiselect>
                <!-- <select
                  class="form-control operator-select"
                  v-model="query.operator"
                  @change="setOperatorDependants"
                >
                  <option
                    v-for="(operator, index) in ruleOperators"
                    :key="index"
                    :value="operator.id"
                    >{{ operator.label }}</option
                  >
                </select> -->
              </div>
            </div>

            <div class="px-1 mb-1" v-if="query.operator && ruleForOptionType">
              <div v-if="ruleForOptionType.type === 'number'">
                <div v-if="isBetweenOperator">
                  <input
                    type="number"
                    v-model="query.value[0]"
                    class="form-control rounded"
                    pattern="[0-9.]+"
                    @focus="disableClone()"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                    data-form-type="other"
                  />
                  <input
                    type="number"
                    v-model="query.value[1]"
                    class="form-control rounded"
                    pattern="[0-9.]+"
                    @focus="disableClone()"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                    data-form-type="other"
                  />
                </div>
                <div v-else>
                  <input
                    type="number"
                    v-model="query.value[0]"
                    class="form-control rounded"
                    pattern="[0-9.]+"
                    @focus="disableClone()"
                    @blur="fetchCount()"
                    @keyup="clearResult()"
                    data-form-type="other"
                  />
                </div>
              </div>

              <div v-else-if="ruleForOptionType.type === 'select'">
                <multiselect
                  v-model="singleValueObj"
                  track-by="id"
                  label="label"
                  :options="ruleOptions"
                  :multiple="false"
                  :close-on-select="true"
                  :showLabels="false"
                  placeholder="Select one"
                  class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                  @close="fetchResultCount()"
                  v-if="showSingleSelect()"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option-text">{{ props.option.label }}</span>
                  </template>
                </multiselect>
                <!-- <select
                  class="form-control value-select"
                  v-model="query.value[0]"
                  @change="fetchResultCount()"
                  v-if="showSingleSelect()"
                >
                  <option
                    v-for="(option, index) in ruleOptions"
                    :key="index"
                    :value="option.id"
                    >{{ option.label }}</option
                  >
                </select> -->
                <multiselect
                  v-else
                  v-model="query.value[0]"
                  track-by="id"
                  label="label"
                  :options="ruleOptions"
                  :multiple="true"
                  :close-on-select="false"
                  :showLabels="false"
                  :placeholder="emptyValue ? 'Select one or more' : ''"
                  class="simple-select group-select form-control rounded advanced-query-select  eo-multi-select"
                  @open="disableClone()"
                  @close="fetchResultCount()"
                >
                  <span
                    class="arrow"
                    slot="caret"
                    slot-scope="{ toggle }"
                    @mousedown.prevent="toggle"
                  >
                    <i class="fa fa-angle-double-down fa-lg double-arrow"></i>
                  </span>
                  <template
                    slot="selection"
                    slot-scope="{ values }"
                    v-if="values.length"
                  >
                    {{ values.length }}
                    {{ values.length | pluralize('option') }} selected
                  </template>
                </multiselect>
              </div>

              <div v-else>
                <input
                  type="text"
                  v-model="query.value[0]"
                  class="form-control rounded"
                  @focus="disableClone()"
                  @blur="fetchCount()"
                  @keyup="clearResult()"
                  data-form-type="other"
                />
              </div>
            </div>

            <div class="close-icon">
              <i
                :class="[
                  enableClone ? 'enabled' : 'disabled',
                  'fa fa-clone pr-2 clone-rule-icon'
                ]"
                @click="cloneRule"
              ></i>
              <i class="fa fa-times-circle fa-lg" @click="removeRule"></i>
            </div>
          </div>
          <div v-else>
            <query-group
              :query="query"
              :rules="rules"
              @updateQuery="updateQuery"
              @removeGroup="removeGroup"
              @clearResult="clearResult"
            ></query-group>
          </div>
        </div>
      </div>
    </div>
    <div class="rule-count pt-3">
      <span v-if="query.loading">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
      <span v-else-if="!hideResultCount">
        {{ query.count | numberFormat }}
        <span class="d-none">{{ resultCount }}</span>
      </span>
    </div>

    <common-metric-tree-modal
      :queryID="query.id"
      :selectedMetricID="query.operand"
      @updateMetric="commonMetricUpdate"
      @updateFIType="fiTypeUpdate"
    ></common-metric-tree-modal>

    <metric-tree-modal
      :queryID="query.id"
      :fiType="query.fiType || 'bank'"
      :selectedMetricID="query.operand"
      @updateMetric="metricUpdate"
      @updateFIType="fiTypeUpdate"
    ></metric-tree-modal>

    <statements-modal
      :queryID="query.id"
      :fiType="query.fiType || 'both'"
      :selectedStatementID="query.operand"
      @updateStatement="statementUpdate"
      @updateFIType="fiTypeUpdate"
    ></statements-modal>

    <technographics-modal
      :queryID="query.id"
      :selectedServiceID="query.operand"
      @updateService="serviceUpdate"
    ></technographics-modal>
  </div>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '@/plugins/events.js'
// ui components
import QueryGroup from './QueryGroup'
import JobSearch from './JobSearch'
import SiteSearch from './SiteSearch'
import ContactSearch from './ContactSearch'
import SelectInstitutionModal from './../../core/components/SelectInstitutionModal.vue'
import UploadInstitutionModal from '@/modules/core/components/UploadInstitutionModal.vue'
import Multiselect from 'vue-multiselect'
import MetricTreeModal from '@/modules/core/components/MetricTreeModal.vue'
import CommonMetricTreeModal from '@/modules/core/components/CommonMetricTreeModal.vue'
import StatementsModal from '@/modules/core/components/StatementsModal.vue'
import TechnographicsModal from '@/modules/core/components/TechnographicsModal.vue'
// specific utilities
import QueryTransformer from '../helpers/query.transformer.js'
// general utilities
import { findDeep } from 'deepdash'
import _ from 'lodash'

export default {
  name: 'query-rule',
  components: {
    QueryGroup,
    JobSearch,
    SiteSearch,
    ContactSearch,
    SelectInstitutionModal,
    Multiselect,
    MetricTreeModal,
    CommonMetricTreeModal,
    StatementsModal,
    TechnographicsModal,
    UploadInstitutionModal
  },
  props: {
    rules: {
      type: Array
    },
    query: {
      type: Object
    },
    rootQuery: {
      type: Object
    },
    groupOperator: {
      type: String
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      resultCount: null,
      enableClone: true,
      segOperand: null,
      ruleIdObj: null,
      operandObj: null,
      childOperandObj: null,
      fitTypeObj: null,
      operatorObj: null,
      singleValueObj: null,
      sourceObj: null,
      selectInstitutionOptions: [
        { label: 'Include', value: 'include' },
        { label: 'Exclude', value: 'exclude' }
      ],
      basicOperators: [
        'equal',
        'less',
        'less or equal',
        'greater',
        'greater or equal',
        'between',
        'not between'
      ]
    }
  },
  mounted() {
    if (this.isSegmentationRule && this.query.operand) {
      this.segOperand = this.chosenRule.children.find(
        child => child.id === this.query.operand
      )
    }
    if (this.query.ruleId) {
      this.ruleIdObj = this.baseOptions.find(
        opt => opt.value == this.query.ruleId
      )
    }
    if (this.query.operand) {
      this.operandObj = this.ruleOperands.find(
        opt => opt.id == this.query.operand
      )
    }
    if (this.query.childOperand) {
      this.childOperandObj = this.ruleChildOperands.find(
        opt => opt.id == this.query.childOperand
      )
    }
    if (this.query.fitType) {
      this.fitTypeObj = this.fitTypeOperands.find(
        opt => opt.id == this.query.fitType
      )
    }
    if (this.query.operator) {
      this.operatorObj = this.ruleOperators.find(
        opt => opt.id == this.query.operator
      )
    }
    if (this.query.source) {
      this.sourceObj = this.selectInstitutionOptions.find(
        opt => opt.value == this.query.source
      )
    }
    if (this.showSingleSelect() && this.query.value[0] != null) {
      this.singleValueObj = this.ruleOptions.find(
        opt => opt.id == this.query.value[0]
      )
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      queryElements: state => state.queryElements,
      elementsInfo: state => state.elementsInfo
    }),
    ...mapGetters('AdvancedQuery', {
      baseOptions: 'baseQueryOptions',
      technographicRules: 'technographicRules',
      isMortgageLender: 'isMortgageLender'
    }),
    segmentElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'segmentation')
    },
    invalidCriteria() {
      const pattern = new RegExp(/[~`!()#$%^&*+=\-[\]\\';,/{}|\\":<>?]/) //unacceptable chars
      return pattern.test(this.query.value[0])
    },
    isRule() {
      return this.query.type === 'rule'
    },
    basicOperandRule() {
      return {
        operators: this.basicOperators,
        children: [],
        type: 'number'
      }
    },
    isBasicOperandRule() {
      return [
        'Detailed Metrics',
        'Common Metrics (Bank/CU)',
        'Detailed Statements',
        'Common Statements (Bank/CU)'
      ].includes(this.chosenRule.label)
    },
    isTreeSelectionRule() {
      return this.isBasicOperandRule || this.isTechnographicsRule
    },
    chosenRule() {
      if (!this.query.ruleId) {
        return null
      }

      return this.rules.find(rule => rule.id === this.query.ruleId)
    },
    ruleOperands() {
      if (!this.chosenRule) {
        return []
      }
      return this.chosenRule.children.map(rule => {
        return { id: rule.id, label: rule.label, element_id: rule.element_id }
      })
    },
    chosenOperandRule() {
      if (!this.query.operand) {
        return null
      }

      if (this.isTreeSelectionRule) {
        return this.isTechnographicsRule
          ? this.matchingTechnographicOperandRule
          : this.basicOperandRule
      } else {
        return this.chosenRule.children.find(
          operand => operand.id === this.query.operand
        )
      }
    },
    matchingTechnographicOperandRule() {
      let matchingService = null

      this.technographicRules.every(rule => {
        matchingService = findDeep(
          rule.children,
          function(bs) {
            return parseInt(bs.element_id) === this.query.operand
          }.bind(this),
          {
            childrenPath: 'children'
          }
        )
        return matchingService ? false : true
      })

      return matchingService ? matchingService.value : {}
    },
    ruleChildOperands() {
      if (!this.hasChildOperand()) {
        return []
      }
      return this.chosenOperandRule.children.map(operand => {
        return { id: operand.id, label: operand.label }
      })
    },
    fitTypeOperands() {
      if (!this.isVendorFit()) {
        return []
      }

      return this.chosenChildOperandRule.operators.vendor_fit.map(operand => {
        return { id: operand, label: operand }
      })
    },
    chosenChildOperandRule() {
      if (!this.query.childOperand) {
        return null
      }
      return this.chosenOperandRule.children.find(
        childOperand => childOperand.id === this.query.childOperand
      )
    },
    ruleOperators() {
      if (!this.hasOperand) {
        return []
      }
      let operandRule = this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule

      if (operandRule) {
        let operators = this.isVendorFit()
          ? operandRule.operators.operators
          : operandRule.operators

        if (this.isNestedOperator(operators)) {
          return operators.map(operator => {
            return { id: operator.label, label: operator.label }
          })
        } else {
          return operators.map(operator => {
            return { id: operator, label: operator }
          })
        }
      } else {
        return []
      }
    },
    chosenOperatorRule() {
      if (!this.query.operator) {
        return null
      }
      let operandRule = this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
      let operators = operandRule.operators
      if (this.isNestedOperator(operators)) {
        return operators.find(
          operator => operator.label === this.query.operator
        )
      } else {
        return this.chosenOperandRule
      }
    },
    multiselectRuleOptions() {
      return this.ruleOptions.map(option => option.id)
    },
    ruleOptions() {
      if (!this.query.operator) {
        return []
      }
      // array is temporary, needs to replaced with proper input field
      let options = this.chosenOperatorRule.options || []
      if (typeof options[0] === 'object') {
        return options.map(option => {
          return { id: option.value, label: option.label }
        })
      } else {
        return options.map(option => {
          return { id: option, label: option }
        })
      }
    },
    hasOperand() {
      return this.hasChildOperand()
        ? this.isVendorFit()
          ? this.query.fitType
          : this.query.childOperand
        : this.query.operand
    },
    isBetweenOperator() {
      return ['between', 'not between'].includes(this.query.operator)
    },
    ruleForOptionType() {
      return this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
    },
    hideResultCount() {
      return this.query.count === null
    },
    emptyValue() {
      let value = this.query.value[0]
      return Array.isArray(value)
        ? this.query.value[0].length === 0
        : [undefined, ''].includes(this.query.value[0])
    },
    isTechnographicsRule() {
      return this.chosenRule.label === 'Technographics'
    },
    isSegmentationRule() {
      return this.chosenRule && this.chosenRule.label === 'Segmentation'
    }
  },
  methods: {
    selectInstitution() {
      if (this.sourceObj) this.query.source = this.sourceObj.value
      this.clearResult()
    },
    getSegmentationDescription(option) {
      let element = this.segmentElementsInfo.find(
        ei => ei.label === option.label
      )
      return element ? element.description : ''
    },
    setSegmentationOperand() {
      this.query.operand = this.segOperand.id
      this.setDependants()
    },
    disableClone() {
      document
        .querySelectorAll('.multiselect__input')
        .forEach(e => e.setAttribute('data-form-type', 'other'))

      this.enableClone = false
    },
    updateQuery(query) {
      this.$emit('updateQuery', query)
    },
    clearResult() {
      this.$emit('clearResult')
    },
    removeGroup(groupId) {
      this.$emit('removeGroup', groupId)
    },
    cloneRule() {
      this.$emit('cloneRule', this.query)
    },
    removeRule() {
      this.$emit('removeRule', this.query.id)
    },
    hasChildOperand() {
      return (
        this.chosenOperandRule &&
        this.chosenOperandRule.children &&
        this.chosenOperandRule.children.length > 0
      )
    },
    isVendorFit() {
      return (
        this.chosenChildOperandRule &&
        this.chosenChildOperandRule.operators.vendor_fit
      )
    },
    isNestedOperator(operators) {
      return operators.length > 0 && typeof operators[0] === 'object'
    },
    setDependants() {
      if (!this.isSegmentationRule && this.operandObj) {
        this.query.operand = this.operandObj.id
      }
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
      if (this.hasChildOperand()) {
        this.query.source = null
      } else {
        this.query.source = this.chosenOperandRule.source
        this.query.cuSource = this.chosenOperandRule.cu_source
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
      }
      this.$emit('clearResult')
    },
    setChildDependants() {
      if (this.childOperandObj)
        this.query.childOperand = this.childOperandObj.id
      this.query.fitType = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
      if (this.chosenChildOperandRule) {
        this.query.source = this.chosenChildOperandRule.source
        this.query.cuSource = this.chosenChildOperandRule.cu_source
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
      }
      this.$emit('clearResult')
    },
    setFitTypeDependents() {
      if (this.fitTypeObj) this.query.fitType = this.fitTypeObj.id
      this.query.operator = null
      this.query.value = []
      if (this.query.fitType) {
        this.query.operator = this.ruleOperators[0].id
      }
      this.$emit('clearResult')
    },
    ruleIdChanged() {
      if (this.ruleIdObj) this.query.ruleId = this.ruleIdObj.value
      this.operandObj = null
      this.childOperandObj = null
      this.fitTypeObj = null
      this.operatorObj = null
      this.singleValueObj = null
      this.sourceObj = null
      this.segOperand = null
      this.query.operand = null
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.value = []
      this.query.count = null
      this.query.source = null
      this.query.cuSource = null
      this.query.operandLabel = null
      this.query.fiType = null
      this.$emit('clearResult')
    },
    showGroupOperator() {
      return this.index !== 0
    },
    ruleDepth() {
      return (this.query.id.split('_').length - 1) % 4
    },
    canFetchCount() {
      if (this.isBetweenOperator) {
        return this.query.value[0] && this.query.value[1]
      } else {
        return !this.emptyValue
      }
    },
    fetchResultCount() {
      if (this.showSingleSelect() && this.singleValueObj) {
        this.query.value[0] = this.singleValueObj.id
      }
      this.$emit('clearResult')
      this.fetchCount()
    },
    fetchCount() {
      this.enableClone = true
      if (this.canFetchCount()) {
        this.query.loading = true
        let qt = new QueryTransformer(this.rootQuery)
        this.$http
          .get('/api/advanced_query/count', {
            params: {
              query: {
                element: qt.transformToElement(this.query),
                criteria: qt.transformToCriteria(this.query)
              },
              is_mbc: this.isMortgageLender
            },
            handleErrors: true
          })
          .then(
            res => {
              if (!_.isEmpty(res.data.errors)) {
                let error = res.data.errors['job_search']
                  ? res.data.errors['job_search']
                  : res.data.errors['site_search']
                  ? res.data.errors['site_search']
                  : res.data.errors['contact_search']
                let message = error.includes('Lexical')
                  ? 'Please make sure the reserved characters(+ - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \\ /) are used appropriately.'
                  : 'Please enter a valid search criteria.'
                this.$toasted.show(message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
              EventBus.$emit('countUpdated', {
                id: this.query.id,
                count: res.data.count
              })
            },
            () => {
              EventBus.$emit('countUpdated', { id: this.query.id, count: null })
            }
          )
      } else if (this.query.count !== null) {
        this.query.count = null
        this.resultCount = null
      }
    },
    fetchSiteResults() {
      this.$refs.siteKeyword.blur()
    },
    fetchContactResults() {
      this.$refs.contactKeyword.blur()
    },
    setOperatorDependants() {
      if (this.operatorObj) this.query.operator = this.operatorObj.id
      this.query.value = []
      this.query.count = null
      this.$emit('clearResult')
    },
    showSingleSelect() {
      return this.isBooleanOption()
    },
    isBooleanOption() {
      return (
        this.multiselectRuleOptions.length === 2 &&
        ((this.multiselectRuleOptions.includes('true') &&
          this.multiselectRuleOptions.includes('false')) ||
          (this.multiselectRuleOptions.includes(1) &&
            this.multiselectRuleOptions.includes(0)))
      )
    },
    showInstitutionsModal(queryID) {
      this.$emit('clearResult')
      this.$bvModal.show(`selectInstitutions_${queryID}`)
    },
    showUploadInstitutionsModal(queryID) {
      this.$emit('clearResult')
      this.$bvModal.show(`uploadInstitutions_${queryID}`)
    },
    showTreeModal() {
      switch (this.chosenRule.label) {
        case 'Detailed Metrics':
          this.query.fiType = this.query.fiType || 'bank'
          this.$bvModal.show(`metricTreeModal-${this.query.id}`)
          break
        case 'Common Metrics (Bank/CU)':
          this.query.fiType = 'both'
          this.$bvModal.show(`commonMetricTreeModal-${this.query.id}`)
          break
        case 'Detailed Statements':
          this.query.fiType = this.query.fiType || 'bank'
          this.$bvModal.show(`commonStatementsModal-${this.query.id}`)
          break
        case 'Common Statements (Bank/CU)':
          this.query.fiType = 'both'
          this.$bvModal.show(`commonStatementsModal-${this.query.id}`)
          break
        case 'Technographics':
          this.$bvModal.show(`technographicsModal-${this.query.id}`)
          break
        default:
          break
      }
    },
    resetOperandDependants() {
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
    },
    serviceUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.id
      this.query.operandLabel = node.text

      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source

      this.$emit('clearResult')
    },
    statementUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.element_id
      this.query.operandLabel = node.text

      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source

      this.$emit('clearResult')
    },
    metricUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.metric_id
      this.query.operandLabel = node.data.text

      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source

      this.$emit('clearResult')
    },
    commonMetricUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.id
      this.query.operandLabel = node.data.text

      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source

      this.$emit('clearResult')
    },
    fiTypeUpdate(fiType) {
      this.query.fiType = fiType
    }
  },
  watch: {
    'query.operand': {
      handler: function() {
        if (this.query.operand) {
          if (this.isSegmentationRule) {
            this.segOperand = this.chosenRule.children.find(
              child => child.id === this.query.operand
            )
          }
          this.operandObj = this.ruleOperands.find(
            opt => opt.id == this.query.operand
          )
        } else {
          this.operandObj = null
        }
      }
    },
    'query.childOperand': {
      handler: function() {
        if (this.query.childOperand) {
          this.childOperandObj = this.ruleChildOperands.find(
            opt => opt.id == this.query.childOperand
          )
        } else {
          this.childOperandObj = null
        }
      }
    },
    'this.query.fitType': {
      handler: function() {
        if (this.query.fitType) {
          this.fitTypeObj = this.fitTypeOperands.find(
            opt => opt.id == this.query.fitType
          )
        } else {
          this.fitTypeObj = null
        }
      }
    },
    'query.ruleId': {
      handler: function() {
        if (this.query.ruleId) {
          this.ruleIdObj = this.baseOptions.find(
            opt => opt.value == this.query.ruleId
          )
        } else {
          this.ruleIdObj = null
        }
      }
    },
    'query.operator': {
      handler: function() {
        if (this.query.operator) {
          this.operatorObj = this.ruleOperators.find(
            opt => opt.id == this.query.operator
          )
        } else {
          this.operatorObj = null
        }
      }
    },
    'query.value': {
      handler: function() {
        if (
          this.query.operator &&
          this.ruleForOptionType &&
          this.ruleForOptionType.type === 'select' &&
          this.showSingleSelect() &&
          this.query.value[0] != null
        ) {
          this.singleValueObj = this.ruleOptions.find(
            opt => opt.id == this.query.value[0]
          )
        } else this.singleValueObj = null
      }
    },
    'query.source': {
      handler: function() {
        if (
          this.chosenRule &&
          this.chosenRule.label === 'Select Institutions'
        ) {
          if (this.query.source) {
            this.sourceObj = this.selectInstitutionOptions.find(
              opt => opt.value == this.query.source
            )
          } else {
            this.sourceObj = null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rule-box-cnt {
  background-color: #f5f5f5;
}

.close-icon {
  margin-left: auto;
}

.rule-block,
.rule-box {
  width: 100%;
}

.rule-operator {
  margin-right: 0.5rem;
  min-width: 2rem;
  font-weight: bold;
}

.rule-count {
  margin-left: 0.5rem;
  min-width: 3.2rem;
  font-weight: bold;
}

.rule-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
  background: #fae3e3;
}

.clone-rule-icon {
  font-size: 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
.option-text {
  font-size: 0.875rem;
}
</style>

<style lang="scss">
.advanced-query-select {
  min-height: 33px;

  .multiselect__select {
    height: 33px;
  }

  .multiselect__tags {
    min-height: 33px;
    padding-top: 0.25rem;

    .multiselect__placeholder {
      margin-bottom: 0.25rem;
    }
  }
}
.simple-select.advanced-query-select {
  .multiselect__tags {
    .multiselect__placeholder {
      margin-bottom: 7px;
    }
  }
}
.advanced-query-select.eo-multi-select {
  .multiselect__select::before {
    border-width: 7px 7px 0;
  }
  .arrow {
    position: absolute;
    right: 0;
    padding-right: 1rem;
    padding-top: 0.35rem;
    .double-arrow {
      font-weight: 900;
    }
  }
  .multiselect__tags {
    padding-top: 0.35rem;
    .multiselect__placeholder {
      padding-top: 0px;
      margin-bottom: 6px;
    }
  }
}

.operand-select {
  max-width: 22rem;
}
</style>
